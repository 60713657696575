import React, {useContext, useEffect, useState} from 'react';
import {Row, Image, Col, Container, Card, Table} from 'react-bootstrap';
import UserOrganisationContext from '../../context/organisation/OrganisationContext';
import './OrganisationStockMarket.css';
import Organisation from '../../model/organisation/Organisation';
import Select from 'react-select';
import Ticker from '../../model/organisation/stockmarket/TickerData';
import axios from 'axios';
import {organisationHostname} from '../../utils/Configuration';
import IqSmallLoadingIcon from '../common/IqSmallLoadingIcon';
import {SelectOption} from '../news/GlobalNews';
import QuarterlyStatement from '../../model/organisation/stockmarket/QuarterlyStatement';
import QuarterlyStatementResponse from '../../model/organisation/stockmarket/QuarterlyStatement';
import IsinData from '../../model/organisation/stockmarket/IsinData';
import 'd3-time-format'
import * as d3 from 'd3';


import {
    faArrowUp,
    faArrowDown,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {formatLocale} from 'd3-format';
import OrganisationLineSeriesChart from './common/OrganisationLineSeriesChart';
import GraphData from '../../model/organisation/stockmarket/GraphData';
import translate from '../../i18n/translate';
import StockMarketLineSeriesChart from './StockMarketLineSeriesChart';
import { matchPath, useLocation } from 'react-router-dom';


const OrganisationStockMarket: React.FC = () => {


    let location = useLocation();


    const {
        organisation,
    } = useContext(UserOrganisationContext);

    const childMatchedPath: any = matchPath(location.pathname, {
        path: '/organisation/:organisationId',
        exact: false,
        strict: false
    });

    const [tickerSelect, setTickerSelect] = useState<Ticker[]>([])
    const [quarterlyStatements, setQuarterlyStatements] = useState<QuarterlyStatementResponse>()
    const [isin, setIsin] = useState<IsinData>();
    const [isinHistoric, setisinHistoric] = useState<GraphData[]>([])
    const [graphData, setGraphData] = useState([] as any);

    const [selectedTicker, setSelectedTicker] = useState<Ticker>()
    const [loading, setLoading] = useState<boolean>(true)

    const getQuarterlyStatements = async () => {

        let currentDate = new Date();
        let currentYear = currentDate.getFullYear();

        await axios.get(organisationHostname + "organisation/" + childMatchedPath?.params.organisationId + "/quarterly-statements",
            {
                params: {
                    year: currentYear
                }
            })
            .then(r => {
                setQuarterlyStatements(r.data);
            })
            .catch(err => console.error(err));
    };

    const getIsinData = async () => {
        await axios.get(organisationHostname + "organisation/ticker-cik/" + childMatchedPath?.params.organisationId)
            .then(r => {
                setIsin(r.data);
            })
            .catch(err => console.error(err));
    };

    const getGraphData = async () => {
        await axios.get(organisationHostname + "organisation/ticker-graph/" + childMatchedPath?.params.organisationId)
            .then(r => {
                setisinHistoric(r.data);
                buildGraphs(r.data)
            })
            .catch(err => console.error(err));
    }

    const buildGraphs = (isinData: GraphData[]) => {
        isinData.forEach((oyv: { price: any; dateFound: any; }) => {
            setGraphData((graphData: any) => [
                    ...graphData,
                    {
                        y: oyv.price,
                        x: new Date(oyv.dateFound).getTime()
                    }
                ]
                    .sort((a, b) => (a.x > b.x) ? 1 : ((b.x > a.x) ? -1 : 0)) // sort by year in ascending order
            );
        });
    }


    enum CURRENCY {
        US = "$",
        UK = "£",
        FR = "€",
        DE = "€"
    }

    enum CURRENCY_TYPE {
        US = "USD",
        UK = "GBP",
        FR = "EUR",
        DE = "EUR"
    }

    const customLocale = formatLocale({
        currency: [CURRENCY[organisation.countryCode], ""],
        decimal: ".",
        thousands: ",",
        grouping: [3]
    });

    const timeFormat = d3.timeFormat("%d %b, %H:%M")

    const TickerCard = () => {

        const priceBeforeChange = isin.changes > 0 ? isin.price - isin.changes : isin.price + isin.changes
        console.log(priceBeforeChange);
        const changePercentage = (((isin.price - priceBeforeChange) / priceBeforeChange) * 100).toFixed(2)

        return (
            <div className="organisation-card w-50">
                <div style={{justifyContent: "space-between"}} className="flex-row ">
                    <div style={{flex: 1}} className="flex-col">
                        <div className="flex-row">
                            <div style={{textAlign: "start"}} className="flex-col w-full">
                                <div className="organisation-card-label">Stock Overview</div>
                                <div>
                                    Mkt Cap: {customLocale.format('$,.3s')(+isin.mktCap).replace(/G/, "B")}
                                </div>
                                <div>
                                    Div Yield: {((isin.lastDiv / isin.price) * 100).toFixed(2)}%
                                </div>
                                <div>
                                    Ticker: {isin.symbol}
                                </div>
                            </div>
                            <div style={{textAlign: "center", alignItems: "center"}} className="flex-col w-full">
                                <div className="organisation-card-label">Current Share Price</div>
                                                 <div className="flex-row  justify-center">
                                                     <span className="isin-price">{isin.price}</span>
                                                     <span className="flex-end pt-4"
                                                           style={{color: "#929292"}}>{[CURRENCY_TYPE[organisation.countryCode]]}</span>
                                                 </div>
                                                 <div className="flex-row  justify-center align-center">
                                                     <span
                                                         className={isin.changes > 0 ? "postive-percent" : "negative-percent"}>{isin.changes > 0 ? "+" : ""}{isin.changes.toFixed(2)}</span>
                                                     <FontAwesomeIcon icon={isin.changes > 0 ? faArrowUp : faArrowDown}
                                                                      color={isin.changes > 0 ? "#90EE90FF" : "#CD5C5CFF"}/>
                                                     <span
                                                         className={parseFloat(changePercentage) > 0 ? "postive-percent" : "negative-percent"}>({changePercentage}%)</span>
                                                     <FontAwesomeIcon icon={parseFloat(changePercentage) > 0 ? faArrowUp : faArrowDown}
                                                                      color={parseFloat(changePercentage) > 0 ? "#90EE90FF" : "#CD5C5CFF"}/>
                            
                                                 </div>
                                                         <span
                                                             style={{color: "#929292"}}>{timeFormat(new Date(isin.lastModified))} • Disclaimer</span>
                            </div>
                            <div style={{textAlign: "end", alignItems: "end"}} className="flex-col w-full">
                                <div className="organisation-card-label">52-Week Range</div>
                                <div>
                                    52wk-Low: {isin.range.split("-")[0]}
                                </div>
                                <div>
                                    52wk-High: {isin.range.split("-")[1]}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const QuarterlyCard = (key: string) => {

        const hasData = quarterlyStatements[key] === null ? false : true

        const cardStyle = {
            opacity: hasData ? 1 : 0.5
        };

        const statement = quarterlyStatements[key]

        return (
            <Card style={cardStyle} className="w-25 header-card pt-3 pb-3">
                <span>
                    {key}
                </span>
                <span className="inner-card">
                  {statement?.month} {statement?.calendarYear}
                </span>
                {hasData && (
                    <Table className="organisation-card-text inner-card">
                        <thead>
                        <tr>
                            <th>
                            </th>
                            <th>
                            </th>
                            <th>
                                Y/Y
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{textAlign: 'right'}} >Revenue</td>
                            <td style={{textAlign: 'right'}} >{customLocale.format('$,.3s')(+statement.revenue).replace(/G/, "B")}</td>
                            <td style={{textAlign: 'left'}} >
                                <div
                                    className={statement.revenuePercentage < 0 ? 'negative-percent' : 'postive-percent'}>
                                    {statement.revenuePercentage.toFixed(2)}%
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{textAlign: 'right'}} >Net Income</td>
                            <td style={{textAlign: 'right'}} >{customLocale.format('$,.3s')(+statement.netIncome).replace(/G/, "B")}</td>
                            <td style={{textAlign: 'left'}} >
                                <div
                                    className={statement.netIncomePercentage < 0 ? 'negative-percent' : 'postive-percent'}>
                                    {statement.netIncomePercentage.toFixed(2)}%
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{textAlign: 'right'}} >Diluted EPS</td>
                            <td style={{textAlign: 'right'}} >{statement.epsdiluted}</td>
                            <td style={{textAlign: 'left'}} >
                                <div
                                    className={statement.epsdilutedPercentage < 0 ? 'negative-percent' : 'postive-percent'}>
                                    {parseFloat(statement.epsdilutedPercentage).toFixed(2)}%
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{textAlign: 'right'}} >Net Margin</td>
                            <td style={{textAlign: 'right'}} >{statement.netProfitMargin.toFixed(2)}%</td>
                            <td style={{textAlign: 'left'}} >
                                <div
                                    className={statement.netProfitPercentage < 0 ? 'negative-percent' : 'postive-percent'}>
                                    {statement.netProfitPercentage.toFixed(2)}%
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </ Table>
                )}
            </Card>)
    }

    const runFunctions = async () => {
        setLoading(true)
        await getIsinData()
        await getQuarterlyStatements()
        await getGraphData()
        setLoading(false)
    }

    useEffect(() => {
        runFunctions()
    }, []);

    return (
        <Container fluid style={{paddingLeft: 0, paddingRight: 0, marginTop: 15, overflowX: "hidden"}}>
            {loading ? <IqSmallLoadingIcon/> :
                <div className="flex-col">
                    <div className="flex-row">
                        <TickerCard />
                        <div style={{minHeight: 250}} className="organisation-card w-50">
                            <div className="organisation-card-label">CHART</div>
                            <div>
                                <StockMarketLineSeriesChart data={graphData}
                                                            colour="#52CAF9"
                                                            title={"Price"}
                                                            yAxisFormat={(value: any) => `${value}`}
                                                            formatValue={(value: any) => {
                                                                return customLocale.format('.2f')(value) + " $";
                                                            }} />
                            </div>

                        </div>
                    </div>
                    <div className="flex-row">
                        <div className="organisation-card w-full">
                            <div className="organisation-card-label">QUARTERS</div>
                            <div className="flex-row">
                                {Object.keys(quarterlyStatements).map((quarter) => QuarterlyCard(quarter))}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Container>
    )
}
export default OrganisationStockMarket;